import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import error404 from "../images/svg/error-404.svg";

export default () => (
  <Layout>
    <SEO
      title="Page Not Found"
      keywords={[
        "electrical",
        "Philippines",
        "design",
        "build",
        "system",
        "supply",
        "troubleshoot",
        "repair",
        "equipments",
        "test",
        "manage",
        "bms"
      ]}
    />
    <div className="bg-gray-100">
      <div className="d-flex align-items-center height-100vh">
        <div className="container text-center">
          <div className="w-lg-60 mx-lg-auto">
            <object
              type="image/svg+xml"
              data={error404}
              aria-label="Page not found"
            ></object>

            <h1 className="display-3 font-size-48--md-down font-weight-medium mb-3">
              Page not found
            </h1>
            <p className="lead mb-0">
              Oops! Looks like you followed a bad link.
            </p>
            <p className="lead mb-0">
              If you think this is a problem with us, please{" "}
              <Link to="/contact-us/#form">tell us</Link>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
